import React from 'react'

export default function Page404() {
    return (
        <div 
            style={{backgroundColor:"#000", color:"#fff", height:"calc(100vh - 85px)"}} 
            className="d-flex align-items-center justify-content-center"
        >
            <div className="text-center" style={{marginBottom:"102px"}}>
                <h1 className="display-1">404</h1>
                <p className="lead">
                    <span className="fw-600">Oops! </span>
                    <span>Parece que la página que buscabas no existe</span>
                </p>
                <a href="/" className="btn btn-light mt-3">Volver al Inicio</a>
            </div>
        </div>
    )
}
