import React from 'react'
import { useField } from 'formik'

const style = {
    fontSize:"0.83em",
    fontStyle:"italic",
    color:"red",
    textAlign:"left",
    padding:"0"
}

const FormInput = ({
    step,
    name,
    type,
    placeholder
}) => {
    const [field, meta] = useField(name);

    return (
        <>
            <input 
                {...field}
                step={step}
                name={field.name}
                value={field.value ? field.value : ''}
                type={type}
                placeholder={placeholder}
                className="form-control p-3"
            />
            {meta.error && meta.touched && 
            <div style={style}>
                {"*" + meta.error}
            </div>}
        </>
    )
}

export default FormInput;