import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../css/pages/Entrenadores.css";

const Entrenadores = () => {
  return (
    <section id="trainers">
      <Container className="container-entrenadores">
        <Row xs={1} md={2}>
          <Col md={5} xs={10} className="d-flex mx-auto  ">
            <Row>
              <img src="/images/Isaias_principal.png" alt="" />
            </Row>
          </Col>

          <Col className="text-light align-self-center txt">
            <h3 className="mb-3 fs-1 fw-600 fst-italic">Quién soy</h3>
            <p className="fs-5">¡Estamos a tu disposición!</p>
            <div className="mt-5">
              <Link to="/nosotros" className="btn-more">
                Conoceme
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Entrenadores;
 