import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap'
import { publicFetch } from '../../util/publicFetch';
import LoadingScreen from '../../LoadingScreen';
import Footer from '../../common/Footer'

import "../../css/pages/Nosotros.css"

const Nosotros = () => {
    const [coaches, setCoaches] = useState();
    const [sedes, setSedes] = useState();

    useEffect(() => {

      const getCoaches = async () => {
        try {
          const { data } = await publicFetch.get(`administration/trainers`);
          setCoaches(data.data); 
        } catch (error) {
          console.log(error);
        }
      }

      const getSedes = async () => {
        try {
          const { data } = await publicFetch.get(`administration/headquarters`, {
            params: {
              page: 1,
              limit: 8
            }
          });  
          setSedes(data.data);
        } catch (error) {
          console.log(error);
        }
      }

      getSedes();
      getCoaches();
    }, [])

    return (
      <>
        {coaches && sedes ? 
        <>
        <section>
          <section className="coaches-fimage">
            <Container fluid className="vh-70 mb-5 position-relative">
              <Row className="h-100">
                <Col className="align-self-center pt-5 text-center text-light">
                  <h3 className="fs-1 text-white px-5 fw-bold">
                      <em>IB Training Program</em>
                  </h3>
                  <p className="lead text-white px-5">
                    Estudio, creo, ejecuto, superviso y sigo creando.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>       

          <Container>
            <div className="mb-5">
              <div className="text-center fs-2 fst-italic fw-700 pb-5">
                HEAD COACH
              </div>

              <Row md={2} className="d-flex justify-content-center">
                <div className="coach-photo-container">
                  <img 
                    src="https://files.catbox.moe/rizosl.png"  
                    alt="Isaias Barbeito" 
                    style={{width:"100%" ,height:"auto", maxWidth:"500px"}}
                  />
                </div>
                <div className="p-4">
                  <div className="fs-2 fw-600">
                    Isaias Barbeito
                  </div>
                  <div className="pt-4 lead texto-container">
                  "Mi nombre es Isaías Barbeito, me dedico a estudiar el entrenamiento desde el año 2013 y tengo experiencia profesional en el mismo desde el año 2014. Soy licenciado en Educación Física, especializado en entrenamiento en múltiples áreas tales como musculación, optimización corporal, levantamiento de pesas, preparación física, personal training, rehabilitación, CR0SSFIT, entrenamiento funcional, halterofilia, streching, entrenamiento deportivo, entrenamiento en suspensión, entre muchas otras.
Podés ver mi trayectoria en el botón de "CV ISAIAS BARBEITO"


                    
                  </div>
<br/>
                  <a 
                            href="https://drive.google.com/drive/folders/1XD2FfyYM12t8NljE31HLohYkB5grdsbt?usp=sharing"                        
                            rel="noopener noreferrer" target="_blank"  
                            className="btn btn-dark img-btn rounded-pill"
                        >
                           CV Isaias Barbeito
                        </a>
                </div>
              </Row>

            </div>

            <div className="border-bottom-0 border"></div>
            <div className="my-5">
              <div className="text-center fs-2 fst-italic fw-700 pb-5">
                STAFF
              </div>
              <Row md={2} className="d-flex justify-content-center">
                <Col md={{ order: 1 }} className="coach-photo-container">
                  <img 
                    src="https://files.catbox.moe/jjsewx.png" 
                    alt=""
                    style={{width:"100%" ,height:"auto", maxWidth:"400px"}}
                  />
                </Col>
                <Col className="p-4 text-md-end">
                
                  <div 
                    className="pt-4 lead texto-container" 
                    
                  >
                    Nuestro equipo de trabajo está conformado 
                    por un staff de profesionales sumamente 
                    capacitados en cada área, trabajando juntos para potenciarte. 
                    Bajo los lineamientos de nuestro Head coach; nuestro equipo de 
                    nutricionistas, programadores, kinesiólogos, masajistas, 
                    comunicadores y method coaches trabajamos juntos para 
                    hacer de tu entrenamiento la mejor y más efectiva experiencia.
                  </div>
                </Col>
              </Row>
          </div>
        </Container>
      </section>  
      
      {/* SEDES

      <section style={{ backgroundColor:"black" }} className="pb-5">
        <Container>
          <div className="text-center text-light fs-1 fst-italic fw-600 p-5">
            Sedes
          </div>

            <Row xs={1} sm={1} lg={3} xxl={4}>
              {sedes.map((i) =>
              <Col 
                className="mb-5 d-flex justify-content-center" 
                key={i.customer_id} 
                xs={12}
              >
                <Card 
                  style={{ width:"300px", height:"auto", overflow:"hidden" }} 
                  bsPrefix="card rounded-0"
                >
                  
                  <div style=
                    {
                      {
                        height: "150px",
                        width:"300px",
                        overflow: "hidden", 
                      }
                    }
                    className="d-flex justify-content-center"
                  >
                    <Card.Img 
                      variant="top" 
                      src={i.headquarter_logo} 
                      className="rounded-0"
                      alt={i.headquarter_name}
                      style={
                        {
                          position:"absolute", 
                          left:"50%", 
                          transform:"translateX(-50%)"
                        }
                      }
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>{i.headquarter_name}</Card.Title>
                    <div>
                      <p>
                        <i class="bi bi-bookmark-star me-2"></i>
                        <a 
                          style={{textDecoration:"none"}} 
                          href={i.headquarter_webpage}
                        >
                            Sitio web
                        </a>
                        <br/>
                        <i class="bi bi-geo-alt me-2"></i>
                        <a 
                          style={{textDecoration:"none"}} 
                          href={i.headquarter_location}
                        >
                            Ubicación
                        </a>
                      </p>
                    </div>
                  </Card.Body>
              </Card>
              </Col>)}
              </Row>

          </Container>
      </section> */}

      <Footer />
      
      </>
      : <LoadingScreen />}
      </>
    );
};

export default Nosotros;