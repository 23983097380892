import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import { AuthContext } from '../../context/AuthContext'

export default function Card(props) {
  const authProvider = useContext(AuthContext);

  return (
    <Col>

      {/*
      <Link 
        to={authProvider.isAuthenticated() ? "/profile/rutina" : "/login"} 
        className="card shadow-1 cardov border-0 m-auto"
      >

        <img 
          src={props.cardphoto} 
          className="card-img" 
          alt="..." 
          width="450" 
          height="650"
        />

        <div 
          className="position-absolute p-4 w-100 text-center"
          style={{top:"50%", transform: "translate(0, -50%)"}} 
        >
            <h5 className="fw-bold">
              {props.title}
            </h5>
            <p>
              {props.description}
            </p>
            <span className="lead">
              {props.price && <span>ARS ${props.price}</span>}
              {props.price && props.price_usd && <span> / </span>}
              {props.price_usd && <span>USD ${props.price_usd}</span>}
              {ARS ${props.price} / USD ${props.price_usd} }
            </span>
        </div>

      </Link >
        */}
      
      <a 
       href="https://wa.me/5492657655195?text=¡¡Tengo%20interés%20en%20unirme%20a%20la%20familia%20IB!!" 
  className="card shadow-1 cardov border-0 m-auto" 
  target="_blank" 
  rel="noopener noreferrer"
      >

        <img 
          src={props.cardphoto} 
          className="card-img" 
          alt="..." 
          width="450" 
          height="650"
        />

        <div 
          className="position-absolute p-4 w-100 text-center"
          style={{top:"50%", transform: "translate(0, -50%)"}} 
        >
            <h5 className="fw-bold">
              {props.title}
            </h5>
            <p>
              {props.description}
            </p>
            <span className="lead">
              {props.price && <span>ARS ${props.price}</span>}
              {props.price && props.price_usd && <span> / </span>}
              {props.price_usd && <span>USD ${props.price_usd}</span>}
              {/*ARS ${props.price} / USD ${props.price_usd}*/ }
            </span>
        </div>

      </a >
        
        
    </Col>
    )
}