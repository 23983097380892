import React from 'react'
import TacElement from './TacElement';
import { elements } from './tacElements'

const Tac = () => {
  return (
    <div>

      <div className="p-5 text-center">
        <div className="fw-700 fs-1">
          TÉRMINOS Y CONDICIONES
        </div>
        <div className="lead text-secondary">
          Última actualización: 25 de Octubre de 2021
        </div>
      </div>

      <hr className="mb-3" />
      {elements.map((o, i) => 
        <TacElement 
          key={i} 
          title={o.title} 
          paragraph={o.paragraph}
          items={o.hasOwnProperty('items') ? o.items : null}
        />
      )}

      <div className="fw-bold w-80 m-auto ps-3 mb-5">
        ISAÍAS BARBEITO <br/>
        (Pendiente) <br/>
        Villa Mercedes, San Luis 5730 <br/>
        Argentina <br/>
        Teléfono: +54 9 2657 357310 <br/>
        ibtpsystem@gmail.com <br/>
      </div>
    </div>
  )
}

export default Tac;
