import React from "react";

export default function Footer() {
  return (
    <div className="container-fluid text-center text-secondary footer">
      <h6 className="m-0 pt-3">© 2021 IB Training Program.</h6>
      <p className="h6 m-0">Entrenamiento | Programaciones en linea</p>
      <br />
    </div>
  );
}
