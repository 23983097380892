import React, { useContext, useState } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { publicFetch } from '../../util/publicFetch';
import { AuthContext } from '../../context/AuthContext';
import { Container, Button } from 'react-bootstrap'
import BackendError from '../../common/BackendError';
import Errors from '../../common/Errors';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Campo email obligatorio.'),
    password: Yup.string()
      .required('Campo contraseña obligatorio.')
})

const Login = () => {
    const authContext = useContext(AuthContext);
    const [loginError, setLoginError] = useState(null);
    const [redirectOnLogin, setRedirectOnLogin] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);

    const submitCredentials = async (logindata) => {
        try {
            setLoginLoading(true);
            const { data } = await publicFetch.post(`access/login`, logindata);
            data.data.access_token && authContext.setAuthState(data.data)
            setLoginError(null);

            setTimeout(() => {setRedirectOnLogin(true);}, 700);
        } catch (error) {
            setLoginLoading(false);
            const { data } = error.response;
            setLoginError(data.data);
        }
    }

    return (
        <>
          {redirectOnLogin && <Redirect to="/" />}
          <section style={{minHeight:"100vh", display:"flex"}}>

            <Container className="loginwrapper d-flex">
                <div className="formcontainer login border shadow pt-5">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        onSubmit={values => submitCredentials(values)}
                        validateOnChange={false}
                        validateOnBlur={false}
                        validationSchema={LoginSchema}
                    >
                        {({errors}) => (
                          <Form>
                            <div className="mb-3 text-center">
                              <img 
                                src="/images/logoibblack75.webp" 
                                alt="Entrar" 
                              />
                            </div>

                            <div className="mb-3 px-4">
                              <Field
                                name="email"
                                type="email"
                                placeholder="Email"
                                className="form-control p-3"
                              />
                            </div>
                            <div className="mb-3 px-4">
                              <Field
                                name="password"
                                type="password"
                                placeholder="Contraseña"
                                className="form-control p-3"
                              />
                            </div>
                            <div className="
                              mb-3 
                              d-flex 
                              flex-column 
                              text-center"
                            >
                              <div>
                                <Link 
                                  to="/" 
                                  className="text-decoration-none p-0"
                                >
                                  Olvidé mi contraseña.
                                </Link>
                              </div>
                              <div>
                                <Link 
                                  to="/signup" 
                                  className="text-decoration-none p-0"
                                >
                                  ¿No tenes cuenta?
                                </Link>
                              </div>
                            </div>

                            <div className="pb-5 text-center ">
                              <Button
                                disabled={loginLoading}
                                type="submit"
                                bsPrefix="btn btn-primary py-2 px-5"
                              >
                                {loginLoading ? 'Cargando...' : 'Entrar'}
                              </Button>
                            </div> 

                            { (Object.keys(errors).length > 0 || loginError) &&
                              <div className="alert alert-danger errorText p-3">
                                <BackendError errors={loginError}/>
                                <Errors errors={errors}/>
                              </div> }
                          </Form>
                        )}
                      </Formik>
                </div>
            </Container>
          </section>
        </>
    )
}

export default Login;