import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import { publicFetch } from '../../util/publicFetch';
import Card from "./Card"

export default function Subscripciones() {

    const [subscriptions, setSubscriptions] = useState([]);
    
    useEffect(() => {
        const getData = async () => {
            try {
                const { data } = await publicFetch.get('programs');
                setSubscriptions(data.data);
            } catch (error) {
                console.log(error);
            }
        }

        getData();
    }, [])

    return (
        <>
        <section id="programas" className="overlay-hidden position-relative">
            <div className="susov"></div>
            <div className="container-fluid sub">
                <h2 className="display-6 text-center py-5 fw-600">
                    PROGRAMAS
                </h2>
                <Row xs={1} md={2} lg={4} xl={4} className="text-white g-4 pb-5">
                    {subscriptions && subscriptions.map((card_data) => 
                        <Card 
                            title={card_data.program_name}
                            description={card_data.program_desc}
                            price={card_data.program_price}
                            price_usd={card_data.program_price_usd}
                            collapse={card_data.program_id}
                            link={card_data.link}
                            cardphoto={card_data.program_cardphoto}
                            key={card_data.program_id}
                        />
                    )}
                </Row>
            </div>
        </section>

        <section style={{backgroundColor:"#fff"}}>
           
        </section>
        </>
    )
}
 