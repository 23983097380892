import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

export default function DoubleContainer() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section
            id="capcon"
            className="overflow-hidden"
            style={{ backgroundColor: "#000" }}
        >
            <div className="d-flex flex-column justify-content-center text-center  text-white p-5 " style={{ width: windowWidth <= 768 ? '100%' : '100%' } && {height:windowWidth <= 768 ? '300px':'500px'}}>
                <h2 className="display-6 fw-600 ">CRECÉ CON NOSOTROS</h2>
                <Row className=" flex-row   pt-5 ">
                    <Col className="py-3 ">
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ibtrainingprogram/">
                            <img style={{ width: windowWidth <= 768 ? '60px' : '80px' }} src="/images/instagram.png" alt="IG" />
                        </a>
                    </Col>
                    <Col className="py-3 ">
                        <a target="_blank" rel="noreferrer" href="https://wa.me/5492657655195?text=¡¡Tengo%20interés%20en%20unirme%20a%20la%20familia%20IB!!" >
                            <img style={{ width: windowWidth <= 768 ? '60px' : '80px' }}  src="https://files.catbox.moe/fnnt3r.png" alt="WhatsApp Business" />
                        </a>
                    </Col>
                    <Col className=" py-3 ">
                        <a  target="_blank" rel="noreferrer" href="https://open.spotify.com/show/7etayXpEWmTyHXK056hkzN?si=RLt_7CVWQZaNhwZQ0ct-mQ&utm_source=copy-link&dl_branch=1">
                            <img style={{ width: windowWidth <= 768 ? '60px' : '80px' }}  src="/images/spotify.png" alt="" />
                        </a>
                    </Col>
                    <Col className=" py-3 ">
                        <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCR2OG1RPAnzkdch1LVdcULw">
                            <img style={{ width: windowWidth <= 768 ? '60px' : '80px' }}  src="/images/youtube.png" alt="" />
                        </a>
                    </Col>
                </Row>
            </div>
        </section>
    );
}
