import React from 'react'

const BackendError = ({errors}) => {
  return (
    <>
    {errors && errors.map(o =>
      <span key={o.param}>
        *{o.msg}<br/>
      </span>)
    }
    </>
  )
}

export default BackendError;