import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'

import App from './App';
import { AuthProvider } from "./components/context/AuthContext"
import { FetchProvider } from './components/context/FetchContext';
import { ProgramProvider } from './components/context/ProgramContext';

ReactDOM.render( 
  <BrowserRouter>
    <AuthProvider>
      <FetchProvider>
        <ProgramProvider>
          <App />
        </ProgramProvider>
      </FetchProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
