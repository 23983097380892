const countries = 
[
    {
        "value": 1,
        "label": "Afghanistan"
    },
    {
        "value": 2,
        "label": "Aland Islands"
    },
    {
        "value": 3,
        "label": "Albania"
    },
    {
        "value": 4,
        "label": "Algeria"
    },
    {
        "value": 5,
        "label": "American Samoa"
    },
    {
        "value": 6,
        "label": "Andorra"
    },
    {
        "value": 7,
        "label": "Angola"
    },
    {
        "value": 8,
        "label": "Anguilla"
    },
    {
        "value": 9,
        "label": "Antarctica"
    },
    {
        "value": 10,
        "label": "Antigua and Barbuda"
    },
    {
        "value": 11,
        "label": "Argentina"
    },
    {
        "value": 12,
        "label": "Armenia"
    },
    {
        "value": 13,
        "label": "Aruba"
    },
    {
        "value": 14,
        "label": "Australia"
    },
    {
        "value": 15,
        "label": "Austria"
    },
    {
        "value": 16,
        "label": "Azerbaijan"
    },
    {
        "value": 17,
        "label": "Bahamas"
    },
    {
        "value": 18,
        "label": "Bahrain"
    },
    {
        "value": 19,
        "label": "Bangladesh"
    },
    {
        "value": 20,
        "label": "Barbados"
    },
    {
        "value": 21,
        "label": "Belarus"
    },
    {
        "value": 22,
        "label": "Belgium"
    },
    {
        "value": 23,
        "label": "Belize"
    },
    {
        "value": 24,
        "label": "Benin"
    },
    {
        "value": 25,
        "label": "Bermuda"
    },
    {
        "value": 26,
        "label": "Bhutan"
    },
    {
        "value": 27,
        "label": "Bolivia"
    },
    {
        "value": 28,
        "label": "Bonaire, Sint Eustatius and Saba"
    },
    {
        "value": 29,
        "label": "Bosnia and Herzegovina"
    },
    {
        "value": 30,
        "label": "Botswana"
    },
    {
        "value": 31,
        "label": "Bouvet Island"
    },
    {
        "value": 32,
        "label": "Brazil"
    },
    {
        "value": 33,
        "label": "British Indian Ocean Territory"
    },
    {
        "value": 34,
        "label": "Brunei Darussalam"
    },
    {
        "value": 35,
        "label": "Bulgaria"
    },
    {
        "value": 36,
        "label": "Burkina Faso"
    },
    {
        "value": 37,
        "label": "Burundi"
    },
    {
        "value": 38,
        "label": "Cambodia"
    },
    {
        "value": 39,
        "label": "Cameroon"
    },
    {
        "value": 40,
        "label": "Canada"
    },
    {
        "value": 41,
        "label": "Cape Verde"
    },
    {
        "value": 42,
        "label": "Cayman Islands"
    },
    {
        "value": 43,
        "label": "Central African Republic"
    },
    {
        "value": 44,
        "label": "Chad"
    },
    {
        "value": 45,
        "label": "Chile"
    },
    {
        "value": 46,
        "label": "China"
    },
    {
        "value": 47,
        "label": "Christmas Island"
    },
    {
        "value": 48,
        "label": "Cocos (Keeling) Islands"
    },
    {
        "value": 49,
        "label": "Colombia"
    },
    {
        "value": 50,
        "label": "Comoros"
    },
    {
        "value": 51,
        "label": "Congo"
    },
    {
        "value": 52,
        "label": "Congo, Democratic Republic of the Congo"
    },
    {
        "value": 53,
        "label": "Cook Islands"
    },
    {
        "value": 54,
        "label": "Costa Rica"
    },
    {
        "value": 55,
        "label": "Cote D'Ivoire"
    },
    {
        "value": 56,
        "label": "Croatia"
    },
    {
        "value": 57,
        "label": "Cuba"
    },
    {
        "value": 58,
        "label": "Curacao"
    },
    {
        "value": 59,
        "label": "Cyprus"
    },
    {
        "value": 60,
        "label": "Czech Republic"
    },
    {
        "value": 61,
        "label": "Denmark"
    },
    {
        "value": 62,
        "label": "Djibouti"
    },
    {
        "value": 63,
        "label": "Dominica"
    },
    {
        "value": 64,
        "label": "Dominican Republic"
    },
    {
        "value": 65,
        "label": "Ecuador"
    },
    {
        "value": 66,
        "label": "Egypt"
    },
    {
        "value": 67,
        "label": "El Salvador"
    },
    {
        "value": 68,
        "label": "Equatorial Guinea"
    },
    {
        "value": 69,
        "label": "Eritrea"
    },
    {
        "value": 70,
        "label": "Estonia"
    },
    {
        "value": 71,
        "label": "Ethiopia"
    },
    {
        "value": 72,
        "label": "Falkland Islands (Malvinas)"
    },
    {
        "value": 73,
        "label": "Faroe Islands"
    },
    {
        "value": 74,
        "label": "Fiji"
    },
    {
        "value": 75,
        "label": "Finland"
    },
    {
        "value": 76,
        "label": "France"
    },
    {
        "value": 77,
        "label": "French Guiana"
    },
    {
        "value": 78,
        "label": "French Polynesia"
    },
    {
        "value": 79,
        "label": "French Southern Territories"
    },
    {
        "value": 80,
        "label": "Gabon"
    },
    {
        "value": 81,
        "label": "Gambia"
    },
    {
        "value": 82,
        "label": "Georgia"
    },
    {
        "value": 83,
        "label": "Germany"
    },
    {
        "value": 84,
        "label": "Ghana"
    },
    {
        "value": 85,
        "label": "Gibraltar"
    },
    {
        "value": 86,
        "label": "Greece"
    },
    {
        "value": 87,
        "label": "Greenland"
    },
    {
        "value": 88,
        "label": "Grenada"
    },
    {
        "value": 89,
        "label": "Guadeloupe"
    },
    {
        "value": 90,
        "label": "Guam"
    },
    {
        "value": 91,
        "label": "Guatemala"
    },
    {
        "value": 92,
        "label": "Guernsey"
    },
    {
        "value": 93,
        "label": "Guinea"
    },
    {
        "value": 94,
        "label": "Guinea-Bissau"
    },
    {
        "value": 95,
        "label": "Guyana"
    },
    {
        "value": 96,
        "label": "Haiti"
    },
    {
        "value": 97,
        "label": "Heard Island and Mcdonald Islands"
    },
    {
        "value": 98,
        "label": "Holy See (Vatican City State)"
    },
    {
        "value": 99,
        "label": "Honduras"
    },
    {
        "value": 100,
        "label": "Hong Kong"
    },
    {
        "value": 101,
        "label": "Hungary"
    },
    {
        "value": 102,
        "label": "Iceland"
    },
    {
        "value": 103,
        "label": "India"
    },
    {
        "value": 104,
        "label": "Indonesia"
    },
    {
        "value": 105,
        "label": "Iran, Islamic Republic of"
    },
    {
        "value": 106,
        "label": "Iraq"
    },
    {
        "value": 107,
        "label": "Ireland"
    },
    {
        "value": 108,
        "label": "Isle of Man"
    },
    {
        "value": 109,
        "label": "Israel"
    },
    {
        "value": 110,
        "label": "Italy"
    },
    {
        "value": 111,
        "label": "Jamaica"
    },
    {
        "value": 112,
        "label": "Japan"
    },
    {
        "value": 113,
        "label": "Jersey"
    },
    {
        "value": 114,
        "label": "Jordan"
    },
    {
        "value": 115,
        "label": "Kazakhstan"
    },
    {
        "value": 116,
        "label": "Kenya"
    },
    {
        "value": 117,
        "label": "Kiribati"
    },
    {
        "value": 118,
        "label": "Korea, Democratic People's Republic of"
    },
    {
        "value": 119,
        "label": "Korea, Republic of"
    },
    {
        "value": 120,
        "label": "Kosovo"
    },
    {
        "value": 121,
        "label": "Kuwait"
    },
    {
        "value": 122,
        "label": "Kyrgyzstan"
    },
    {
        "value": 123,
        "label": "Lao People's Democratic Republic"
    },
    {
        "value": 124,
        "label": "Latvia"
    },
    {
        "value": 125,
        "label": "Lebanon"
    },
    {
        "value": 126,
        "label": "Lesotho"
    },
    {
        "value": 127,
        "label": "Liberia"
    },
    {
        "value": 128,
        "label": "Libyan Arab Jamahiriya"
    },
    {
        "value": 129,
        "label": "Liechtenstein"
    },
    {
        "value": 130,
        "label": "Lithuania"
    },
    {
        "value": 131,
        "label": "Luxembourg"
    },
    {
        "value": 132,
        "label": "Macao"
    },
    {
        "value": 133,
        "label": "Macedonia, the Former Yugoslav Republic of"
    },
    {
        "value": 134,
        "label": "Madagascar"
    },
    {
        "value": 135,
        "label": "Malawi"
    },
    {
        "value": 136,
        "label": "Malaysia"
    },
    {
        "value": 137,
        "label": "Maldives"
    },
    {
        "value": 138,
        "label": "Mali"
    },
    {
        "value": 139,
        "label": "Malta"
    },
    {
        "value": 140,
        "label": "Marshall Islands"
    },
    {
        "value": 141,
        "label": "Martinique"
    },
    {
        "value": 142,
        "label": "Mauritania"
    },
    {
        "value": 143,
        "label": "Mauritius"
    },
    {
        "value": 144,
        "label": "Mayotte"
    },
    {
        "value": 145,
        "label": "Mexico"
    },
    {
        "value": 146,
        "label": "Micronesia, Federated States of"
    },
    {
        "value": 147,
        "label": "Moldova, Republic of"
    },
    {
        "value": 148,
        "label": "Monaco"
    },
    {
        "value": 149,
        "label": "Mongolia"
    },
    {
        "value": 150,
        "label": "Montenegro"
    },
    {
        "value": 151,
        "label": "Montserrat"
    },
    {
        "value": 152,
        "label": "Morocco"
    },
    {
        "value": 153,
        "label": "Mozambique"
    },
    {
        "value": 154,
        "label": "Myanmar"
    },
    {
        "value": 155,
        "label": "Namibia"
    },
    {
        "value": 156,
        "label": "Nauru"
    },
    {
        "value": 157,
        "label": "Nepal"
    },
    {
        "value": 158,
        "label": "Netherlands"
    },
    {
        "value": 159,
        "label": "Netherlands Antilles"
    },
    {
        "value": 160,
        "label": "New Caledonia"
    },
    {
        "value": 161,
        "label": "New Zealand"
    },
    {
        "value": 162,
        "label": "Nicaragua"
    },
    {
        "value": 163,
        "label": "Niger"
    },
    {
        "value": 164,
        "label": "Nigeria"
    },
    {
        "value": 165,
        "label": "Niue"
    },
    {
        "value": 166,
        "label": "Norfolk Island"
    },
    {
        "value": 167,
        "label": "Northern Mariana Islands"
    },
    {
        "value": 168,
        "label": "Norway"
    },
    {
        "value": 169,
        "label": "Oman"
    },
    {
        "value": 170,
        "label": "Pakistan"
    },
    {
        "value": 171,
        "label": "Palau"
    },
    {
        "value": 172,
        "label": "Palestinian Territory, Occupied"
    },
    {
        "value": 173,
        "label": "Panama"
    },
    {
        "value": 174,
        "label": "Papua New Guinea"
    },
    {
        "value": 175,
        "label": "Paraguay"
    },
    {
        "value": 176,
        "label": "Peru"
    },
    {
        "value": 177,
        "label": "Philippines"
    },
    {
        "value": 178,
        "label": "Pitcairn"
    },
    {
        "value": 179,
        "label": "Poland"
    },
    {
        "value": 180,
        "label": "Portugal"
    },
    {
        "value": 181,
        "label": "Puerto Rico"
    },
    {
        "value": 182,
        "label": "Qatar"
    },
    {
        "value": 183,
        "label": "Reunion"
    },
    {
        "value": 184,
        "label": "Romania"
    },
    {
        "value": 185,
        "label": "Russian Federation"
    },
    {
        "value": 186,
        "label": "Rwanda"
    },
    {
        "value": 187,
        "label": "Saint Barthelemy"
    },
    {
        "value": 188,
        "label": "Saint Helena"
    },
    {
        "value": 189,
        "label": "Saint Kitts and Nevis"
    },
    {
        "value": 190,
        "label": "Saint Lucia"
    },
    {
        "value": 191,
        "label": "Saint Martin"
    },
    {
        "value": 192,
        "label": "Saint Pierre and Miquelon"
    },
    {
        "value": 193,
        "label": "Saint Vincent and the Grenadines"
    },
    {
        "value": 194,
        "label": "Samoa"
    },
    {
        "value": 195,
        "label": "San Marino"
    },
    {
        "value": 196,
        "label": "Sao Tome and Principe"
    },
    {
        "value": 197,
        "label": "Saudi Arabia"
    },
    {
        "value": 198,
        "label": "Senegal"
    },
    {
        "value": 199,
        "label": "Serbia"
    },
    {
        "value": 200,
        "label": "Serbia and Montenegro"
    },
    {
        "value": 201,
        "label": "Seychelles"
    },
    {
        "value": 202,
        "label": "Sierra Leone"
    },
    {
        "value": 203,
        "label": "Singapore"
    },
    {
        "value": 204,
        "label": "Sint Maarten"
    },
    {
        "value": 205,
        "label": "Slovakia"
    },
    {
        "value": 206,
        "label": "Slovenia"
    },
    {
        "value": 207,
        "label": "Solomon Islands"
    },
    {
        "value": 208,
        "label": "Somalia"
    },
    {
        "value": 209,
        "label": "South Africa"
    },
    {
        "value": 210,
        "label": "South Georgia and the South Sandwich Islands"
    },
    {
        "value": 211,
        "label": "South Sudan"
    },
    {
        "value": 212,
        "label": "Spain"
    },
    {
        "value": 213,
        "label": "Sri Lanka"
    },
    {
        "value": 214,
        "label": "Sudan"
    },
    {
        "value": 215,
        "label": "Suriname"
    },
    {
        "value": 216,
        "label": "Svalbard and Jan Mayen"
    },
    {
        "value": 217,
        "label": "Swaziland"
    },
    {
        "value": 218,
        "label": "Sweden"
    },
    {
        "value": 219,
        "label": "Switzerland"
    },
    {
        "value": 220,
        "label": "Syrian Arab Republic"
    },
    {
        "value": 221,
        "label": "Taiwan, Province of China"
    },
    {
        "value": 222,
        "label": "Tajikistan"
    },
    {
        "value": 223,
        "label": "Tanzania, United Republic of"
    },
    {
        "value": 224,
        "label": "Thailand"
    },
    {
        "value": 225,
        "label": "Timor-Leste"
    },
    {
        "value": 226,
        "label": "Togo"
    },
    {
        "value": 227,
        "label": "Tokelau"
    },
    {
        "value": 228,
        "label": "Tonga"
    },
    {
        "value": 229,
        "label": "Trinidad and Tobago"
    },
    {
        "value": 230,
        "label": "Tunisia"
    },
    {
        "value": 231,
        "label": "Turkey"
    },
    {
        "value": 232,
        "label": "Turkmenistan"
    },
    {
        "value": 233,
        "label": "Turks and Caicos Islands"
    },
    {
        "value": 234,
        "label": "Tuvalu"
    },
    {
        "value": 235,
        "label": "Uganda"
    },
    {
        "value": 236,
        "label": "Ukraine"
    },
    {
        "value": 237,
        "label": "United Arab Emirates"
    },
    {
        "value": 238,
        "label": "United Kingdom"
    },
    {
        "value": 239,
        "label": "United States"
    },
    {
        "value": 240,
        "label": "United States Minor Outlying Islands"
    },
    {
        "value": 241,
        "label": "Uruguay"
    },
    {
        "value": 242,
        "label": "Uzbekistan"
    },
    {
        "value": 243,
        "label": "Vanuatu"
    },
    {
        "value": 244,
        "label": "Venezuela"
    },
    {
        "value": 245,
        "label": "Viet Nam"
    },
    {
        "value": 246,
        "label": "Virgin Islands, British"
    },
    {
        "value": 247,
        "label": "Virgin Islands, U.s."
    },
    {
        "value": 248,
        "label": "Wallis and Futuna"
    },
    {
        "value": 249,
        "label": "Western Sahara"
    },
    {
        "value": 250,
        "label": "Yemen"
    },
    {
        "value": 251,
        "label": "Zambia"
    },
    {
        "value": 252,
        "label": "Zimbabwe"
    }
]

export default countries