import React, { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup'
import { publicFetch } from '../../util/publicFetch'
import { Redirect, Link } from 'react-router-dom'
import { Row, Col, Button, Container, Form as FormB } from 'react-bootstrap'
import FormInput from '../../FormInput';
import FormSelect from '../../FormSelect';
import { genders } from '../../../selectOptions';
import countries from '../../../countries'
import BackendError from '../../common/BackendError';
import Errors from '../../common/Errors';

const SignupSchema = Yup.object().shape({
    customer_name: Yup.string()
        .min(2, '¡Muy corto!')
        .max(30, 'Muy largo!')
        .required('Requerido'),
    customer_surname: Yup.string()
        .min(2, '¡Muy corto!')
        .max(30, 'Muy largo!')
        .required('Requerido'),
    customer_email: Yup.string()
        .email('Email invalido.')
        .required('Requerido'),
    customer_password: Yup.string()
        .min(6, '¡Muy corto!')
        .required('Requerido'),
    customer_birthday: Yup.date()
        .required('Requerido'),
    customer_weight: Yup.number()
        .min(40, '¡Muy poco!')
        .max(600, '¡Muy grande!')
        .required('Requerido'),
    customer_height: Yup.number()
        .min(100, '¡Muy poco!')
        .max(250, '¡Muy grande!')
        .required('Requerido'),
    country_id: Yup.number()
        .required('Requerido'),
    customer_gender: Yup.string()
        .required('Requerido'),
    tac: Yup.bool()
        .required('*Requerido')
})

const style = {
    fontSize:"0.83em",
    fontStyle:"italic",
    color:"red",
    textAlign:"left",
    padding:"0"
}

const SignUp = () => {
    const [signupSuccess, setSignupSuccess] = useState();
    const [signupError, setSignupError] = useState();
    const [redirectOnLogin, setRedirectOnLogin] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);

    const submitCredentials = async (credentials) => {
        try {
            setLoginLoading(true);
            const { data } = await publicFetch.post(`customers`, credentials);
            setSignupSuccess(data);
            setSignupError(null);

            setTimeout(() => {
                setRedirectOnLogin(true);
            }, 700);
        } catch (error) {
            setLoginLoading(false);
            const { data } = error.response;
            setSignupError(data.data);
            setSignupSuccess(null);
        }
    }

    return (
        <>
        {redirectOnLogin && <Redirect to="/" />}
        <section style={{minHeight:"100vh", display:"flex"}}>

          <Container className="loginwrapper d-flex" style={{paddingTop:"86px"}}>
            <div className="formcontainer signup border shadow pt-5">
                <Formik
                    initialValues={{
                        customer_name: '',
                        customer_surname: '',
                        customer_email: '',
                        customer_password: '',
                        customer_birthday: '',
                        customer_weight: undefined,
                        customer_height: undefined,
                        customer_gender: '',
                        country_id: ''
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={values => submitCredentials({...values})}
                    validationSchema={SignupSchema}
                >
                    {({errors, touched}) => (
                    <Form>
                        <div className="text-center">
                            <img 
                                src="/images/logoibblack75.webp" 
                                alt="Entrar" 
                            />
                        </div>
                        <div className="
                            m-2 fs-4 fw-700 fst-italic 
                            text-center text-secondary"
                        >
                            REGISTRO
                        </div>
                        <Row className="mb-3">
                            <Col>
                                <FormInput 
                                    name="customer_name"
                                    placeholder="Nombre"
                                />
                            </Col>
                            <Col>
                                <FormInput 
                                    name="customer_surname"
                                    placeholder="Apellido"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FormInput
                                    name="customer_email"
                                    type="email"
                                    placeholder="Email"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FormInput 
                                    name="customer_password"
                                    type="password"
                                    placeholder="Contraseña"
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FormB.Label>Fecha de nacimiento:</FormB.Label>
                                <FormInput 
                                    name="customer_birthday"
                                    type="date"
                                />
                            </Col>
                            <Col>
                                <FormB.Label>País:</FormB.Label>
                                <FormSelect 
                                    name="country_id"
                                    options={countries}
                                />
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <FormB.Label>Peso:</FormB.Label>
                                <FormInput 
                                    name="customer_weight"
                                    type="number"
                                    placeholder="kg"
                                />
                            </Col>
                            <Col>
                                <FormB.Label>Altura:</FormB.Label>
                                <FormInput 
                                    name="customer_height"
                                    type="number"
                                    placeholder="cm"
                                />
                            </Col>
                            <Col>
                                <FormB.Label>Sexo:</FormB.Label>
                                <FormSelect
                                    name="customer_gender" 
                                    options={genders}
                                />
                            </Col>
                        </Row>
                        

                        <div>
                            <Field 
                                name="tac" 
                                type="checkbox"
                            />
                            &nbsp;He leido y acepto los&nbsp;
                            <Link to="/tac" style={{textDecoration: 'none'}}>
                                Terminos y Condiciones.
                            </Link>
                        </div>

                        <div style={style}>
                            {errors.tac && touched.tac && <div>{errors.tac}<br/></div>}
                        </div>
                        <hr />

                        <div className="mb-3 text-center">
                            <Button
                                disabled={loginLoading}
                                type="submit"
                                bsPrefix="btn btn-primary py-2 px-5"
                            >
                                {loginLoading ? 'Cargando...' : 'Registrarse'}
                            </Button>
                        </div>

                        <div className="mb-3 text-center">
                            <Link to="/login" className="text-decoration-none">
                                ¿Ya tienes cuenta? ¡Logueate!
                            </Link>
                        </div>

                        { (Object.keys(errors).length > 0 || signupError) &&
                            <div className="alert alert-danger errorText p-3">
                            <BackendError errors={signupError}/>
                            <Errors errors={errors}/>
                        </div> }
                    </Form>
                    )}
                </Formik>
                {signupSuccess && 
                    <div className="alert alert-success">
                        ¡Cuenta creada con exito!
                    </div>
                }
            </div>
          </Container>
        </section>  
        </>
    );
};

export default SignUp;