import React, { useContext,useState} from 'react'
import { Link } from 'react-router-dom'
import { Navbar , Nav } from 'react-bootstrap'

import { AuthContext } from "../context/AuthContext"
import "../css/NavBar.css"

export default function NavBar() {
    const authContext = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (

        <Navbar expand="lg" variant="dark" fixed="top">
            <div className="container-fluid">
                <Navbar.Brand className="ms-3" href="/#">
                    <img src="/images/logoib65.webp" alt="IB Gym" />
                </Navbar.Brand>

                <button className="navbar-toggler" type="button" onClick={toggleMenu}>
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
                    <ul className="navbar-nav ms-auto me-2">
                        <Nav.Link href="/#" className="ms-3" onClick={closeMenu}>
                            Inicio
                        </Nav.Link>
                        <Nav.Link href="/#trainers" className="ms-3" onClick={closeMenu}>
                            Quién Soy
                        </Nav.Link>
                        <Nav.Link href="/#programas" className="ms-3" onClick={closeMenu}>
                            Programas
                        </Nav.Link>
                        <Nav.Link href="/#capcon" className="ms-3" onClick={closeMenu}>
                            Contacto
                        </Nav.Link>
                        <li className="nav-item">
                            <div className="separator d-none d-lg-block d-xl-block mx-4" />
                        </li>
                        {!authContext.isAuthenticated() ?
                            <Link to="/login" className="btn btn-primary" onClick={closeMenu}>
                                Iniciar Sesión
                            </Link> :
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle menu-btn"
                                    href="#"
                                    id="navbarDropdownMenuLink"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="true"
                                    aria-expanded="false"
                                    data-bs-display="static"
                                    
                                >
                                    <span>Menú</span>
                                </a>
                                <ul
                                    className="dropdown-menu dropdown-menu-dark dropdown-menu-md-end"
                                    aria-labelledby="navbarDropdownMenuLink"
                                >
                                    <li className="p-1">
                                        <Link to="/profile/rutina" className="btn btn-primary text-start w-100" onClick={closeMenu}>
                                            Planificación del día
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/profile/ranking" className="dropdown-item" onClick={closeMenu}>
                                            Test y Rankings
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/profile" className="dropdown-item" onClick={closeMenu}>
                                            Perfil
                                        </Link>
                                    </li>
                                    <li>
                                        <hr className="dropdown-divider" />
                                    </li>
                                    <li>
                                        <button onClick={authContext.logout} className="dropdown-item">
                                            Salir
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        }
                    </ul>
                </div>
            </div>
        </Navbar>


        // <Navbar expand="lg" variant="dark" fixed="top">

        //     <Navbar.Brand className="ms-3" href="/#">
        //         <img 
        //             src="/images/logoib65.webp" 
        //             alt="IB Gym" 
        //         />
        //     </Navbar.Brand>

        //     {/* If logged in
        //     {
        //         authContext.isAuthenticated() ?
        //             <>
        //                 <li className="nav-item me-3 dropdown d-none d-lg-block d-xl-block">
        //                 <button
        //                     style={{border:"none", color:"inherit", background:"none", padding:"0"}} 
        //                     className="nav-link dropdown-toggle d-flex align-items-center" 
        //                     id="navbarDropdown" 
        //                     data-bs-toggle="dropdown" 
        //                     aria-expanded="false"
        //                 >
        //                     <div className="btn btn-outline-light me-2">
        //                         Menú
        //                     </div>
        //                 </button>
        //                 <ul 
        //                     className="dropdown-menu dropdown-menu-end" 
        //                     aria-labelledby="navbarDropdown"
        //                     style={{width:"200px"}}
        //                 >
        //                     <li className="p-1">
        //                         <Link 
        //                             to="/profile/rutina" 
        //                             className="btn btn-primary text-start w-100"
        //                         >
        //                             Planificación del día
        //                         </Link>
        //                     </li>
        //                     <li>
        //                         <Link to="/profile/ranking" className="dropdown-item">
        //                             Test y Rankings
        //                         </Link>
        //                     </li>
        //                     <li>
        //                         <Link to="/profile" className="dropdown-item">
        //                             Perfil
        //                         </Link>
        //                     </li> 
        //                     <li>
        //                         <hr className="dropdown-divider" />
        //                     </li>         
        //                     <li>
        //                         <button 
        //                             onClick={authContext.logout} 
        //                             className="dropdown-item"
        //                         >
        //                             Salir
        //                         </button>
        //                     </li>
                            
        //                 </ul>
        //                 </li>
        //                 <CompactNavLogged />
        //             </>
        //         : 
        //             <Link 
        //                 to="/login" 
        //                 className="btn btn-primary ms-auto me-3 d-lg-none d-xl-none"
        //             >
        //                 Iniciar Sesión
        //             </Link>
        //     } */}
            
        //     {/* NavBar */}
        //     <button 
        //         className="navbar-toggler me-3" 
        //         data-toggle="collapse" 
        //         data-target="#navbarSupportedContent" 
        //         aria-controls="navbarSupportedContent" 
        //         aria-expanded="false" 
        //         aria-label="Toggle navigation"
        //     >
        //         <span className="navbar-toggler-icon"></span>
        //     </button>

        //     <Navbar.Collapse id="navbarSupportedContent">
        //         <Nav className="ms-auto">
        //             <Nav.Link href="/#" className="ms-3">
        //                 Inicio
        //             </Nav.Link>
        //             <Nav.Link href="/#nosotros" className="ms-3">
        //                 Nosotros
        //             </Nav.Link>
        //             <Nav.Link href="/#programas" className="ms-3">
        //                 Programas
        //             </Nav.Link>
        //             <Nav.Link href="/#capcon" className="ms-3">
        //                 Contacto
        //             </Nav.Link>
        //             <li className="nav-item">
        //                 <div className="separator d-none d-lg-block d-xl-block mx-4" />
        //             </li>

        //             {/* If logged in */}
        //         {   
        //             authContext.isAuthenticated() ?
        //             <>
        //                 <div className="me-3 btn-group dropdown">
        //                     <button 
        //                         className="btn btn-outline-light me-2 menu-btn dropdown-toggle"
        //                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        //                         id="navbarDropdown"
        //                     >
        //                         Menú
        //                     </button>
        //                     <ul 
        //                         className="dropdown-menu" 
        //                         aria-labelledby="navbarDropdown"
        //                         style={{width:"200px"}}
        //                     >
        //                         <li className="p-1">
        //                             <Link 
        //                                 to="/profile/rutina" 
        //                                 className="btn btn-primary text-start w-100"
        //                             >
        //                                 Planificación del día
        //                             </Link>
        //                         </li>
        //                         <li>
        //                             <Link to="/profile/ranking" className="dropdown-item">
        //                                 Test y Rankings
        //                             </Link>
        //                         </li>
        //                         <li>
        //                             <Link to="/profile" className="dropdown-item">
        //                                 Perfil
        //                             </Link>
        //                         </li> 
        //                         <li>
        //                             <hr className="dropdown-divider" />
        //                         </li>         
        //                         <li>
        //                             <button 
        //                                 onClick={authContext.logout} 
        //                                 className="dropdown-item"
        //                             >
        //                                 Salir
        //                             </button>
        //                         </li>
        //                     </ul>
        //                 </div>
        //                 {/* <CompactNavLogged /> */}
        //             </>
        //         : 
        //             <Link 
        //                 to="/login" 
        //                 className="btn btn-primary ms-auto me-3 d-lg-none d-xl-none"
        //             >
        //                 Iniciar Sesión
        //             </Link>
        //     }
        //             {/* If logged in */}
        //             {/* {authContext.isAuthenticated() ? 
        //             <li className="nav-item me-3 dropdown d-none d-lg-block d-xl-block">
        //                 <button
        //                     style={{border:"none", color:"inherit", background:"none", padding:"0"}} 
        //                     className="nav-link dropdown-toggle d-flex align-items-center" 
        //                     id="navbarDropdown" 
        //                     data-bs-toggle="dropdown" 
        //                     aria-expanded="false"
        //                 >
        //                     <div className="btn btn-outline-light me-2">
        //                         Menú
        //                     </div>
        //                 </button>
        //                 <ul 
        //                     className="dropdown-menu dropdown-menu-end" 
        //                     aria-labelledby="navbarDropdown"
        //                     style={{width:"200px"}}
        //                 >
        //                     <li className="p-1">
        //                         <Link 
        //                             to="/profile/rutina" 
        //                             className="btn btn-primary text-start w-100"
        //                         >
        //                             Planificación del día
        //                         </Link>
        //                     </li>
        //                     <li>
        //                         <Link to="/profile/ranking" className="dropdown-item">
        //                             Test y Rankings
        //                         </Link>
        //                     </li>
        //                     <li>
        //                         <Link to="/profile" className="dropdown-item">
        //                             Perfil
        //                         </Link>
        //                     </li> 
        //                     <li>
        //                         <hr className="dropdown-divider" />
        //                     </li>         
        //                     <li>
        //                         <button 
        //                             onClick={authContext.logout} 
        //                             className="dropdown-item"
        //                         >
        //                             Salir
        //                         </button>
        //                     </li>
                            
        //                 </ul>
        //                 </li>
        //                 : <Link to="/login" className="btn btn-primary me-3 d-none d-lg-block d-xl-block">Iniciar Sesión</Link>} */}
        //             </Nav>


        //     </Navbar.Collapse>
        // </Navbar> 
    )
}

// function CompactNavLogged () {
//     const authContext = useContext(AuthContext);

//     return (
//         <div className="dropdown d-lg-none ms-auto d-xl-none me-3">
//             <button
//                 style={{border:"none", color:"inherit", background:"none", paddgin:"0"}} 
//                 className="nav-link dropdown-toggle link-light d-flex align-items-center" 
//                 id="navbarDropdown" 
//                 data-bs-toggle="dropdown" 
//                 aria-expanded="false"
//             >
//                 <div className="btn btn-outline-light me-2">Menú</div>
//             </button>
//             <ul 
//                 className="dropdown-menu dropdown-menu-end justify-content-start" 
//                 aria-labelledby="navbarDropdown"
//                 style={{width:"200px"}}
//             >
//                 <li className="px-1">
//                     <Link to="/profile/rutina" className="btn btn-primary w-100 text-start">
//                         Planificación del día
//                     </Link>
//                 </li>
//                 <li><Link to="/profile/ranking" className="dropdown-item">Test y Rankings</Link></li>
//                 <li><Link to="/profile" className="dropdown-item">Perfil</Link></li>       
//                 <li><button onClick={authContext.logout} className="dropdown-item">Salir</button></li>   
//             </ul>
//         </div>
//     )
// }