import React, { useState, useContext } from 'react';
import { Container, Row, Col, Button, Form as FormB } from 'react-bootstrap';
import { FetchContext } from '../../context/FetchContext';
import { AuthContext } from '../../context/AuthContext';
import { Form, Formik } from 'formik';
import * as Yup from 'yup'
import FormInputNormal from '../../FormInputNormal'
import '../../css/pages/Store.css'

const paymentSchema = Yup.object().shape({
  cardNumber: Yup.string().required('Obligatorio').min(16, 'Muy corto').max(16, 'Muy grande'),
  cardHolderName: Yup.string().required('Obligatorio'),
  cardExpirationMonth: Yup.string()
    .required('Obligatorio')
    .max(2, 'XX'),
  cardExpirationYear: Yup.string()
    .required('Obligatorio')
    .max(4, 'XXXX')
    .min(4, 'XXXX'),
  securityCode: Yup.string()
    .required('Obligatorio')
    .min(3, 'XXX')
    .max(3, 'XXX'),
})

const Store = () => {
  const fetchContext = useContext(FetchContext);
  const authContext = useContext(AuthContext);

  const [program, setProgram] = useState();
  const [level, setLevel] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();

  const getCardToken = async (cardData) => {
    try {
      const data = await window.MercadoPago.createCardToken({...cardData});
      submitToken(data.id);
    } catch (error) {
      console.log(error);
    }
  }

  const submitToken = async (cardToken) => {
    try {
      const { data } = await fetchContext.authAxios.post(`payment`, {
        token: cardToken,
        program_id: program,
        customer_program_level: level
      });
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {
        authContext.authState.customer && 
        ( 
          authContext.authState.customer.customer_id === 14 || 
          authContext.authState.customer.customer_id === 15
        ) &&
      <>

      <Container style={{paddingTop:"85px"}} className="border">

        <div className="fs-1 text-center fw-700 pt-3">
          <em>
            TIENDA
          </em>
        </div>
        
        <div className="w-50 m-auto p-5" style={{minWidth:"320px"}}>
          <div className="text-center mb-3">Elige tu programa:</div>
          <Row xs={1} lg={4}>
            <Col 
              className={`p-2 ${program === 1 ? 'pbox-c' : 'pbox'}`}
              onClick={() => {setProgram(1)}}
            >
              Athlete
            </Col>
            <Col 
              className={`p-2 ${program === 2 ? 'pbox-c' : 'pbox'}`}
              onClick={() => {setProgram(2)}}
            >
              GAP
            </Col>
            <Col 
              className={`p-2 ${program === 3 ? 'pbox-c' : 'pbox'}`}
              onClick={() => {setProgram(3)}}
            >
              Conditioning
            </Col>
            <Col 
              className={`p-2 ${program === 4 ? 'pbox-c' : 'pbox'}`}
              onClick={() => {setProgram(4)}}
            >
              WOD Program
            </Col>
          </Row>
        </div>

        <div className={`${(!program || program === 4) ? 'd-none' : 'lcon'}`}>

          <div className="border border-bottom-0 mb-5"></div>

          <div className="text-center mb-3">Elige tu nivel:</div>

          <div 
            style={{width:"70%"}} 
            className={`m-auto mb-5`}
          >
            {
              program === 1 && 
                <Row xs={1} lg={3} className="justify-content-center">
                <Col 
                  className={`${level === 1 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(1)}}
                >
                  <div className="fs-3 fw-700 pt-2">ROOKIE</div>
                  <p className="text-justify p-2">
                    Para el atleta novato. Esta es tu programación 
                    si estás empezando de 0 o si hace
                    menos de 6 meses que hacés CR0SSFIT.
                  </p>
                </Col>
                <Col 
                  className={`${level === 2 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(2)}}
                >
                  <div className="fs-3 fw-700 pt-2">MEDIUM</div>
                  <p className="text-justify p-2">
                    Para el atleta intermedio – avanzado. 
                    Esta es tu programación si hace más de 6 meses
                    que hacés CR0SSFIT.
                  </p>
                </Col>
                <Col 
                  className={`${level === 3 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(3)}}
                >
                  <div className="fs-3 fw-700 pt-2">PRO</div>
                  <p className="text-justify p-2">
                    Para el atleta RX o Élite. 
                    Esta es tu programación si estás apuntando a competir
                    dentro de estas dos categorías.
                  </p>
                </Col>
              </Row>          
            }
            {
              program === 2 &&
                <Row xs={1} lg={3} className="justify-content-center">
                  <Col 
                    className={`${level === 1 ? 'lbox-c' : 'lbox'}`} 
                    onClick={() => {setLevel(1)}}
                  >
                    <div className="fs-3 fw-700 pt-2">ROOKIE</div>
                    <p className="p-2">
                      Para la persona que hace menos de 6 meses que entrena ¿sos vos?
                    </p>
                  </Col>
                  <Col 
                    className={`${level === 3 ? 'lbox-c' : 'lbox'}`} 
                    onClick={() => {setLevel(3)}}
                  >
                    <div className="fs-3 fw-700 pt-2">PRO</div>
                    <p className="p-2">
                      Para la persona que hace más de 6 meses que entrena ¿sos vos?
                    </p>
                  </Col>
                </Row>
            }
            {
              program === 3 &&
              <Row xs={1} lg={3} className="justify-content-center">
                <Col 
                  className={`${level === 1 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(1)}}
                >
                  <div className="fs-3 fw-700 pt-2">ROOKIE</div>
                  <p className="text-justify p-2">
                    Si lo hacés unicamente por salud o estás empezando de 0; 
                    esta es tu programación.
                  </p>
                </Col>
                <Col 
                  className={`${level === 2 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(2)}}
                >
                  <div className="fs-3 fw-700 pt-2">MEDIUM</div>
                  <p className="text-justify p-2">
                    Si lo hacés por fitness o hace más de 6 meses que entrenás; 
                    esta es tu programación.
                  </p>
                </Col>
                <Col 
                  className={`${level === 3 ? 'lbox-c' : 'lbox'}`} 
                  onClick={() => {setLevel(3)}}
                >
                  <div className="fs-3 fw-700 pt-2">PRO</div>
                  <p className="text-justify p-2">
                    Si lo hacés para forjar una base sólida y de calidad en 
                    tu rendimiento físico y hace
                    más de 6 meses que entrenás; esta es tu programación
                  </p>
                </Col>
              </Row>
            }
          </div>
        </div>

        <div className={`p-4 ${(level || program===4) && program ? 'd-block' : 'd-none'}`}>
          <div className="border border-bottom-0 mb-4"></div>
          <div className="text-center">Datos de la tarjeta:</div>
          <Formik 
            initialValues={
              {
                cardNumber: '',
                cardHolderName: '',
                cardExpirationMonth: '',
                cardExpirationYear: '',
                securityCode: ''
              }
            }
            validationSchema={paymentSchema}
            onSubmit={values => getCardToken(values)}
          >
            {() => (
              <Form>
                <div className="pform">
                  <FormB.Label>Número:</FormB.Label>
                  <FormInputNormal 
                      name="cardNumber"
                  />
                  <FormB.Label>Nombre:</FormB.Label>
                  <FormInputNormal 
                      name="cardHolderName"
                  />
                  <Row>
                    <Col className="mb-3">
                      <FormB.Label>Mes:</FormB.Label>
                      <FormInputNormal 
                        name="cardExpirationMonth"
                      />
                    </Col>
                    <Col>
                      <FormB.Label>Año:</FormB.Label>
                      <FormInputNormal
                        name="cardExpirationYear"
                      />
                    </Col>
                    <Col className="mb-3">
                      <FormB.Label>CVV</FormB.Label>
                      <FormInputNormal 
                        name="securityCode"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button 
                      type="submit"
                      bsPrefix="pformbtn"
                    >
                      Pagar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </Container>
      </>
      }
    </>
  )
}

export default Store;