import React from "react";

import ImgPrincipal from "./ImgPrincipal";
import Informacion from "./Informacion";
import Subscripciones from "./Subscripciones";
//import Afiliados from "./Afiliados";
import Footer from "../../common/Footer";
import DoubleContainer from "./DoubleContainer";
import Entrenadores from "./Entrenadores";
//import Resultados from "./Resultados";
import "../../css/pages/Home.css";

function Test() {
  return (
    <div>
      <ImgPrincipal />
      <Entrenadores />
      <Subscripciones />
      <DoubleContainer />
      <Informacion />
      <Footer />
    </div>
  );
}

export default Test;
