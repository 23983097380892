import React from 'react'

const TacElement = ({title, paragraph, items}) => {
  return (
    <div className="w-80 m-auto p-3">

      <div className='fs-2 fw-600 p-3 text-center'>{title}</div>
      <div className="lead fs-5" style={{textAlign: 'justify'}}>
        {paragraph}
      </div>

      { items &&
        <div>
          <ul className="p-3">
            {items.map((o, i) => 
              <li
                className="fs-6 lead" 
                key={i}
              >
                {o}
              </li>
            )}
          </ul>
        </div>
      }
    </div>
  )
}

export default TacElement;
