import React from 'react'
import { useField } from 'formik'
import Select from 'react-select'

const style = {
    fontSize:"0.83em",
    fontStyle:"italic",
    color:"red",
    textAlign:"left",
    padding:"0"
}

const customStyle = {
    indicatorSeparator: base => ({
        ...base,
        display: "none",
    }),
    control: base => ({
        ...base,
        height: 58,
        minHeight: 58, 
    }),
}

const FormSelect = ({
    name,
    options,
}) => {
    const [field, meta, helper] = useField(name);

    return (
        <>
            <Select 
                {...field}
                name={field.name}
                value={options ? options.find(option => option.value === field.value) : ''}
                onChange={(o) => helper.setValue(o.value)}
                options={options}
                isSearchable={ false }
                styles={customStyle}
                placeholder="Selecciona..."
            />
            {meta.error && meta.touched && 
            <div style={style}>
                {"*" + meta.error.value}
            </div>}
        </>
    )
}

export default FormSelect;