import React, { createContext, useState } from 'react';
import { publicFetch } from '../util/publicFetch';

const ProgramContext = createContext();
const { Provider } = ProgramContext;

const ProgramProvider = ({ children }) => {

    const [programs, setPrograms] = useState(null);
    const [levels, setLevels] = useState(null);

    const getPrograms = async () => {
        const { data } = await publicFetch.get('programs');
        setPrograms(data.data);
        parseLevels(data.data);
    };

    const parseLevels = (programs) => {
        setLevels(programs.map((o) => o.program_levels));
    };


    return (
        <Provider
            value={{
                getPrograms,
                programs,
                levels,
            }}
        >
            {children}
        </Provider>
    );
};

export { ProgramContext, ProgramProvider }