import React from 'react'
import { Col, Row, Container } from 'react-bootstrap';
import "../../css/pages/ImgPrincipal.css"

export default function ImgPrincipal() {
    return (
        <>
        <section className="header">
            <Container className="position-relative w-100 vh-100">
                <Row 
                    className="h-100 justify-content-center align-items-center text-center">
                    <Col>
                        <h1 className="text-h1">
                            <em>¿ESTÁS LISTO PARA TRANSFORMARTE?</em>
                        </h1>
                        <p className="text-span lead">
                            Programaciones a distancia al alcance de un click.
                        </p>
                        <a 
                            href="https://wa.me/5492657655195?text=¡¡Tengo%20interés%20en%20unirme%20a%20la%20familia%20IB!!"                        
                            rel="noopener noreferrer" target="_blank"  
                            className="btn btn-primary img-btn"
                        >
                            ¡Empezá ya!
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
        <section style={{backgroundColor:"#000000"}}>
           
        </section>
        </>



    )
}
