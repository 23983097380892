import React from 'react'

const Errors = ({errors}) => {
  return (
    Object.keys(errors).length > 0 &&
    Object.values(errors).map((o) => <div><div key={o}>*{o}</div></div>)
  )
}

export default Errors;
