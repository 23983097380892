import React from 'react'

export default function Informacion() {
    return (
        <section className="position-relative" id="nosotros">
            <div className="container">
                <div className="row justify-content-center align-items-center text-center">
                    <div className="col">
                        <h1 className="display-5 text-white pb-2 fw-600">
                            IB Training Program
                        </h1>
                        <div className="nosotros-line mx-auto my-3" />
                            <h3 className="text-white py-3 fw-lighter">
                                <em>
                                Cumplí tus objetivos, 
                                nosotros te acompañamos en el proceso.
                                </em>
                            </h3>
                            <p className="text-white pt-2">
                            </p>

                      </div>
                </div>
            </div>
        </section>
    )
}
