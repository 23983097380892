import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
    const history = useHistory();

    const access_token = localStorage.getItem('access_token');
    const logged = localStorage.getItem('logged');
    const refresh_token = localStorage.getItem('refreshToken');
    const token_expiration_date = localStorage.getItem('token_expiration_date');
    const customer = JSON.parse(localStorage.getItem('customer'));

    const [ authState, setAuthState ] = useState({
        access_token,
        customer,
        logged,
        refresh_token,
        token_expiration_date
    });

    const setAuthInfo = ({ access_token, customer, logged, refresh_token, token_expiration_date }) => {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('customer', JSON.stringify(customer));
        localStorage.setItem('logged', logged);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('token_expiration_date', token_expiration_date);
        
        setAuthState({ access_token, customer, logged, refresh_token, token_expiration_date});
    };

    const logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('logged');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_creation_date');
        localStorage.removeItem('customer');
        setAuthState({});
        history.push('/login');
    }

    const isAuthenticated = () => {
        return authState.logged;
    };

    const isAdmin = () => {
        return authState.customer.customer_access_level === 3;
    };

    const isModerator = () => {
        return authState.customer.customer_access_level === 4;
    };

    const getAccessLevel = () => {
        return authState.customer.customer_access_level;
    }

    const getProgram = () => {
        return authState.customer.program_id;
    }

    const isConditioning = () => {
        return authState.customer.program_id === 2;
    }

    const isActive = () => {
        for (let i = 0; i < authState.customer.subscriptions.length; i++) {
            if (authState.customer.subscriptions[i].subscription_active) return true;
        }
        return false;
    }

    const isAthlete = () => {
        return authState.customer.program_id === 1;
    }

    const getLevel = () => {
        return authState.customer.customer_program_level;
    }

    return (
        <Provider
            value={{
                authState,
                setAuthState: authInfo => setAuthInfo(authInfo),
                isActive,
                logout,
                isAuthenticated,
                getAccessLevel,
                getProgram,
                isAdmin,
                isModerator,
                isAthlete,
                isConditioning,
                getLevel
            }}
        >
            {children}
        </Provider>
    );
};

export { AuthContext, AuthProvider }
