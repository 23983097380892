import React, { lazy, Suspense, useState } from "react";
import { Link ,Switch, Route, BrowserRouter as Router } from "react-router-dom"

import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'reactjs-popup/dist/index.css';
import './components/css/App.css';

import NavBar from "./components/common/NavBar";

import Home from "./components/pages/Home/Home" 
import Login from "./components/pages/Login/Login"
import Signup from "./components/pages/SignUp/SignUp"
import Page404 from "./components/pages/Page404"
import Nosotros from "./components/pages/Nosotros/Nosotros"
import LoadingScreen from "./components/LoadingScreen"
import Store from "./components/pages/Store/Store"
import Tac from "./components/pages/Tac/Tac"

import { ProtectedRoute } from './protectedRoute'

const ProfileRoutes = lazy(() => 
  import("./components/pages/Profile/ProfileRoutes")
)

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <Router>

        <NavBar isMenuOpen={isMenuOpen} handleMenuToggle={handleMenuToggle} closeMenu={closeMenu} />
        <div style={{marginTop:"85px"}}></div>

        <Switch>

          <Route exact path="/" component={Home} onClick={closeMenu} />
          <Route exact path="/login" component={Login} onClick={closeMenu}/>
          <Route exact path="/signup" component={Signup} onClick={closeMenu} />
          <Route exact path="/nosotros" component={Nosotros}  onClick={closeMenu}/>
          <Route exact path="/tac" component={Tac} onClick={closeMenu}/>

          <Suspense fallback={<LoadingScreen/>}>
            <Route 
              exact path="/store"
              component={Store}
            />
            
            <ProtectedRoute 
              path="/profile"
              component={ProfileRoutes}
            />
          </Suspense>

          <Route component={Page404} />
        </Switch>
      </Router>
    </>
  )
}

export default App;
