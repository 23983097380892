import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router';
import { AuthContext } from './components/context/AuthContext'

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const authContext = useContext(AuthContext);

    return (
        <Route {...rest} render={(props) => {
            if (authContext.isAuthenticated()) return <Component {...props} />
            else return <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        }
    } /> )
}