export const genders = [
    {
        label: "Hombre",
        value: "male"
    },
    {
        label: "Mujer",
        value: "female"
    }
]

export const bool = [
    {
        label: "Sí",
        value: 1
    },
    {
        label: "No",
        value: 0
    }
]

export const paginationTotalItems = [
    {
        value: 3,
        label: "3"
    },
    {
        value: 10,
        label: "10"
    },
    {
        value: 30,
        label: "30"
    },
    {
        value: 50,
        label: "50"
    },
]

export const dayOfWeek = [
    {
        value: 1,
        label: "Lunes"
    },
    {
        value: 2,
        label: "Martes"
    },
    {
        value: 3,
        label: "Miercoles"
    },
    {
        value: 4,
        label: "Jueves"
    },
    {
        value: 5,
        label: "Viernes"
    },
    {
        value: 6,
        label: "Sabado"
    },
    {
        value: 7,
        label: "Domingo"
    }
]

export const access = [
    {
        label: 'Usuario',
        value: 1
    },
    {
        label: 'Entrenador',
        value: 2
    },
    {
        label: 'Administrador',
        value: 3
    },
    {
        label: 'Moderador',
        value: 4
    }
]