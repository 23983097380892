import React from 'react'

export default function LoadingScreen() {
  return (
    <div>
      <div className="vh-80 d-flex justify-content-center align-items-center">
        <img 
          className="logoloading" 
          src="/images/logoibblack1000x1000.webp" 
          alt=""
        />
      </div>
    </div>
    )
}
